import React from 'react'
import Title from '../Title';
import styles from '../../css/contact.module.css';
import { FaMapMarker, FaEnvelope, FaPhone } from "react-icons/fa"

const Contact = () => {
    return (
        <section className={styles.contact}>
            <Title title="Neem" subtitle="contact op" />
            <div className={styles.center}>
                <form action="https://formspree.io/info@stichtingmetuitgestrektehand.nl" method="POST" className={styles.form}>
                    <div>
                        <input type="text" name="naam" id="name" placeholder="naam" className={styles.formControl} required />
                    </div>
                    <div>
                        <input type="text" name="onderwerp" id="subject" placeholder="onderwerp" className={styles.formControl} required />
                    </div>
                    <div>
                        <input type="email" name="email" id="email"  placeholder="email" className={styles.formControl} required />
                    </div>   
                    <div>
                        <textarea name="bericht" id="message" rows="10" placeholder="typ hier je bericht" className={styles.formControl} required />
                    </div>        
                    <div>
                        <input type="submit" value="Verzenden" className={styles.submit} />    
                    </div>          
                </form>
                <div className={styles.info}>
                    <div className={styles.items}>
                        <div className={styles.item}>
                            <FaMapMarker className={styles.icon}/>
                            <p>Rengersland 48
                            3853 ET Ermelo</p>
                        </div>
                        <div className={styles.item}>
                            <FaEnvelope className={styles.icon}/>
                            <p>info@stichtingmetuitgestrektehand.nl</p>
                        </div>
                        <div className={styles.item}>
                            <FaPhone className={styles.icon} />
                            <p>+31 6 51 23 25 57</p>
                        </div>
                        <div className={styles.item}>
                            <p>KvK-nummer: 08146559</p>
                        </div>
                        <div className={styles.item}>
                            <p>RSIN: 816008073</p>
                        </div>
                    </div>
                </div>
            </div> 
        </section>
    )
}

export default Contact
