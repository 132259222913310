import React from 'react';
import Layout from '../components/Layout';
import StyledHero from '../components/StyledHero';
import Contact from '../components/Contact/Contact';
import Seo from '../components/Seo';
import { graphql } from 'gatsby';

const contact = ({ data: { img } }) => {
	return (
		<Layout>
			<Seo title='Contact' />
			<StyledHero img={img.childImageSharp.fluid} />
			<Contact />
		</Layout>
	);
};

export const query = graphql`
	query {
		img: file(relativePath: { eq: "connectBcg.jpeg" }) {
			childImageSharp {
				fluid(quality: 90, maxWidth: 4160) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
	}
`;
export default contact;
